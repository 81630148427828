import React from "react"
import { graphql, Link } from "gatsby"
// import Img from "gatsby-image"
import Imgix from "react-imgix"
import htmlToText from "html-to-text"
import Layout from "../components/layout"

import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faFolderOpen } from "@fortawesome/free-regular-svg-icons"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

export default ({ data, location, pageContext }) => (
  <Layout>
    <SEO
      pagetitle="RECENT POSTS"
      pagedesc="Blog投稿記事一覧"
      pagepath={location.pathname}
    />
    <section className="content bloglist">
      <div className="container">
        <h2 className="pagetitle">RECENT POSTS</h2>
        <div className="posts">
          {data.allMicrocmsBlog.edges.map(({ node }) => (
            <article className="post" key={node.id}>
              <Link to={`/blog/post/${node.slug}/`}>
                <figure>
                <Imgix
                  src={node.eyecatch.url}
                  sizes="(max-width: 1200px) 100vw, 1200px"
                  htmlAttributes={{
                     alt: "",
                  }}
                />
                </figure>
                <h3>{node.title}</h3>
                <div className="info">
                  <time dateTime={node.publishDate}>
                    <FontAwesomeIcon icon={faClock} />
                    {node.publishDateJP}
                  </time>
                  <div className="cat">
                    <FontAwesomeIcon icon={faFolderOpen} />
                    <ul>
                      {node.category.map(cat => (
                        <li className={cat.categorySlug} key={cat.id}>{cat.category}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                  <p>{`${htmlToText
                    .fromString(node.content, {
                      ignoreImage: true,
                      ignoreHref: true,
                    })
                    .slice(0, 60)}…`}
                  </p>
              </Link>
            </article>
          ))}
        </div>
        <ul className="pagenation">
          {!pageContext.isFirst && (
            <li className="prev">
              <Link
                to={
                  pageContext.currentPage === 2
                    ? `/blog/`
                    : `/blog/${pageContext.currentPage - 1}/`
                }
                rel="prev"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>前のページ</span>
              </Link>
            </li>
          )}
          {!pageContext.isLast && (
            <li className="next">
              <Link to={`/blog/${pageContext.currentPage + 1}/`} rel="next">
                <span>次のページ</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMicrocmsBlog(
      sort: { order: DESC, fields: publishDate }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          title
          id
          slug
          publishDateJP: publishDate(formatString: "YYYY年MM月DD日")
          publishDate
          content
          category {
            category
            categorySlug
            id
          }
          eyecatch {
            url
          }
        }
      }
    }
  }
`
